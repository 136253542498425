<template>
  <div class="cpt-mount cpt-MMC_Gimbal_ZT30N">
    <div class="bd">
      <div class="form-wrap mount-form">
        <div class="form-item">
          <div class="label-box">控制速度</div>
          <div class="input-box">
            <div class="speedC">
              <div class="speed" :style="control_speed == index + 1
                  ? 'background: #FFFFFF;color: #333333;'
                  : ''
                " v-for="(item, index) in new Array(5).fill(1)" :key="index" @click="control_speed = index + 1">
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">云台模式</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_ptz_mode" v-model="ptz_mode">
              <el-radio size="mini" v-for="item in drd.ptz_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">HDR</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_HDR" v-model="HDR">
              <el-radio size="mini" v-for="item in drd.HDR" :key="item.id" :label="item.id">{{ item.label }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">相机模式</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_camera_mode" v-model="camera_mode">
              <el-radio size="mini" v-for="item in drd.camera_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;倍
          </div>
          <div class="mr10" @mousedown="handle_zoom_b(-1)" @mouseup="stophandle_zoom_b" @mouseleave="stophandle_zoom_b">
            <span style="font-size: 20px; color: #009aff; padding: 0 5px;" class="icon el-icon-remove-outline"></span>
          </div>
          <el-slider v-model="handle_zoom_b_value" :max="50" :min="0" style="width: 180px" @change="stophandle_zoom_b"
            @input="onChange_zoom_b_value"></el-slider>
          <div class="ml10" @mouseenter="handle_zoom_b(1)" @mouseleave="stophandle_zoom_b"  @mouseup="stophandle_zoom_b">
            <span style="font-size: 20px; color: #009aff; padding: 0 5px;"
              class="icon el-icon-circle-plus-outline"></span>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;焦
          </div>
          <div class="mr10" @mousedown="handle_zoom(-1)" @mouseup="stopChange" @mouseleave="stopChange">
            <span style="font-size: 20px; color: #009aff; padding: 0 5px;" class="icon el-icon-remove-outline"></span>
          </div>
          <el-slider @input="onChangezoom" v-model="value" :max="50" :min="0" style="width: 180px"
            @change="stopChange"></el-slider>
          <!-- -->
          <div class="ml10" @mouseenter="handle_zoom(1)" @mouseup="stopChange" @mouseleave="stopChange">
            <span style="font-size: 20px; color: #009aff; padding: 0 5px;"
              class="icon el-icon-circle-plus-outline"></span>
          </div>
        </div>



        <div class="form-item">
          <div class="label-box">
            俯&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;仰
          </div>
          <div class="input-box">
            <el-input style="width: 120px" v-model.number="pitch" size="mini" placeholder="请输入俯仰" />
            <span class="icon el-icon-remove-outline" @click="pitch--"></span>
            <span class="icon el-icon-circle-plus-outline" @click="pitch++"></span>
            <el-button style="
                background: linear-gradient(
                  180deg,
                  rgba(28, 91, 197, 0.69) 0%,
                  #091a38 100%
                );
                opacity: 0.8;
                border: 1px solid #488cff;
                color: #9fc4ff;
              " @click="handle_change_pitch" size="mini" type="primary">设置</el-button>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            航&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;向
          </div>
          <div class="input-box">
            <el-input style="width: 120px" v-model.number="yaw" size="mini" placeholder="请输入航向" />
            <span class="icon el-icon-remove-outline" @click="yaw--"></span>
            <span class="icon el-icon-circle-plus-outline" @click="yaw++"></span>
            <el-button style="
                background: linear-gradient(
                  180deg,
                  rgba(28, 91, 197, 0.69) 0%,
                  #091a38 100%
                );
                opacity: 0.8;
                border: 1px solid #488cff;
                color: #9fc4ff;
              " @click="handle_change_yaw" size="mini" type="primary">设置</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="rocker-wrap">
      <div class="title-box">
        <div class="title">
          <div class="item-group ml20">
            <div @click="handle_take_photo" class="item-box">
              <el-tooltip class="item" effect="dark" content="拍照" placement="top">
                <div class="icon-box">
                  <span class="el-icon-camera"></span>
                </div>
              </el-tooltip>
            </div>
            <div @click="handle_record" class="item-box">
              <el-tooltip class="item" effect="dark" content="录像" placement="top">
                <div class="icon-box">
                  <span class="el-icon-video-camera"></span>
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="rocker-box">
        <div class="rocker" ref="rocker"></div>
      </div>
    </div>
  </div>
</template>

<script>
import nipplejs from "nipplejs";
import { MMC_Gimbal_ZT30N, MMC_Gimbal_Z40, MMC_Gimbal_Z40N } from "../utils";

export default {
  props: {
    whachChange: {
      typeof: Number,
      default: 0
    }
  },
  data() {
    return {
      joystick: null,
      control_speed: 3,
      ptz_mode: 0,
      image_mode: 0,
      thermal_mode: 0,
      thermal_zoom: 1,
      value: 25,
      value2: 25,
      handle_zoom_b_value2: 25,
      handle_zoom_b_value: 25,
      radio: 1,
      pitch: 0,
      yaw: 0,
      camera_mode: 0,
      HDR: 0,
      drd: {
        ptz_mode: [
          { id: 0, label: "锁定" },
          { id: 1, label: "跟随" },
          { id: 2, label: "回中" },
        ],
        HDR: [
          { id: 0, label: "关闭" },
          { id: 1, label: "HDR" },
          { id: 2, label: "HDR+" },
        ],
        camera_mode: [
          { id: 0, label: "录像" },
          { id: 2, label: "拍照" },
        ],
      },
    };
  },
  methods: {
    // 变倍
    handle_zoom_b(value) {
      if (value == -1) {
        this.commit_directive([165,4,3,0,4]);
      } else if (value == 1) {
        this.commit_directive([165,4,3,2,102]);
      }

    },
    stophandle_zoom_b() {
      this.handle_zoom_b_value = 25;

      this.commit_directive([165,4,3,1,53]);
    },
    onChange_zoom_b_value(e) {
      if (e == 25) {
        this.stophandle_zoom_b()
      } else if (e > 25) {
        this.handle_zoom_b(1);
      } else if (e < 25) {
        this.handle_zoom_b(-1);
      }
    },
    handle_zoom(value) {
      if (value == -1) {
        this.commit_directive([165, 17, 4, 0, 0, 138]);
      } else if (value == 1) {
        this.commit_directive([165, 17, 4, 2, 0, 83]);
      }
    },
    onChangezoom(e) {
      // if (this.value2 < this.value) {
      //   this.handle_zoom(1);
      // } else if (this.value2 > this.value) {
      //   this.handle_zoom(-1);
      // }else if(e ==25){
      //   this.stopChange()
      // }
      if (e == 25) {
        this.stopChange()
      } else if (e > 25) {
        this.handle_zoom(1);
      } else if (e < 25) {
        this.handle_zoom(-1);
      }
      // this.value2 = e;


    },
    stopChange() {

      this.value = 25;
      this.commit_directive([165, 17, 4, 1, 0, 126]);

    },
    // 俯仰航向
    handle_change_pitch() {
      let buffer = MMC_Gimbal_Z40N.change_pitch_angle(this.pitch);
      this.commit_directive(buffer);
    },
    handle_change_yaw() {
      let buffer = MMC_Gimbal_Z40N.change_yaw_angle(this.yaw);
      this.commit_directive(buffer);
    },
    // 拍照
    handle_take_photo() {
      let { position } = this;
      let buffer = MMC_Gimbal_Z40N.take_photo(position || {});
      this.commit_directive(buffer);
    },
    // 录像
    handle_record() {
      this.record = !this.record;
      let buffer = MMC_Gimbal_Z40N.record(this.record);
      this.commit_directive(buffer);
    },
    handle_change_HDR(value) {
      // hdr+ 
      let hdr_ = [165, 9, 3, 2, 91]
      // hdr
      let hdr = [165, 9, 3, 1, 8]
      // 关闭
      let close = [165, 9, 3, 0, 57]
      if (value == 0) {
        this.commit_directive(close);
      } else if (value == 1) {
        this.commit_directive(hdr);
      } else if (value == 2) {
        this.commit_directive(hdr_);

      }

    },
    // 相机模式
    handle_change_camera_mode(value) {
      let lx = [165, 31, 3, 0, 56]
      let pz = [165, 31, 3, 1, 9]
      if (value == 0) {
        this.commit_directive(lx);
      } else {
        this.commit_directive(pz);

      }
    },



    handle_change_ptz_mode(value) {
      let buffer = MMC_Gimbal_Z40N.gimbal_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_thermal_zoom(value) {
      let buffer = MMC_Gimbal_ZT30N.gimbal_thermal_zoom(value);
      this.commit_directive(buffer);
    },

    commit_directive(buffer) {
      console.log(buffer, 'buffer');
      this.$emit("directive", buffer);
    },
    init_rocker() {
      this.joystick = nipplejs.create({
        zone: this.$refs["rocker"],
        mode: "static",
        position: {
          left: "50%",
          top: "50%",
        },
        color: "#9bacbe",
      });

      this.joystick
        .on("start", this.start_timer)
        .on("end", this.stop_timer)
        .on(
          "dir:up plain:up dir:left plain:left dir:down plain:down dir:right plain:right",
          (evt, data) => {
            const dir = data.direction.angle;
            switch (dir) {
              case "up":
                this.buffer = MMC_Gimbal_Z40N.gimbal_pitch_ctrl(
                  1,
                  this.control_speed
                );
                break;
              case "right":
                this.buffer = MMC_Gimbal_Z40N.gimbal_yaw_ctrl(
                  1,
                  this.control_speed
                );
                break;
              case "left":
                this.buffer = MMC_Gimbal_Z40N.gimbal_yaw_ctrl(
                  -1,
                  this.control_speed
                );
                break;
              case "down":
                this.buffer = MMC_Gimbal_Z40N.gimbal_pitch_ctrl(
                  -1,
                  this.control_speed
                );
            }
          }
        );
    },
    start_timer() {
      this.buffer = null;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.buffer) {
            this.commit_directive(this.buffer);
          }
        }, 200);
      }
    },
    stop_timer() {
      this.buffer = MMC_Gimbal_ZT30N.gimbal_pitch_ctrl(0, this.control_speed);
      this.commit_directive(this.buffer);

      this.buffer = MMC_Gimbal_ZT30N.gimbal_yaw_ctrl(0, this.control_speed);
      this.commit_directive(this.buffer);

      if (this.buffer) {
        this.buffer = null;
      }
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
  watch: {
    whachChange() {
      if (this.joystick) {
        this.joystick.destroy()
      }
      this.init_rocker()
    }
  },
  mounted() {
    this.$emit("width", '416px')
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
  beforeDestroy() {
    this.stop_timer();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/index.scss";
</style>